<template>
  <v-card
    flat
    py-2
    class="my-1"
    :class="{ failure: !truck.wifi_works, [truckEnvironment]: true }"
  >
    <v-row>
      <v-col class="px-8 py-2">
        <v-row>
          <v-col cols="12" sm="5">
            <v-menu
              transition="slide-y-transition"
              right
              borderless
              offset-y
              nudge-bottom="6"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="purple"
                  color="blue"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left dark>mdi-truck</v-icon>
                  Truck {{ truck.truck_id }}
                </v-btn>
              </template>

              <v-btn-toggle>
                <v-btn
                  depressed
                  small
                  dark
                  color="blue"
                  :to="`/explore/${truck.id}`"
                  class="hidden-sm-and-down"
                >
                  <v-icon left dark>mdi-magnify</v-icon> Inspect
                </v-btn>
                <v-btn
                  depressed
                  small
                  dark
                  color="green"
                  @click="identify(truck.id)"
                  class="hidden-sm-and-down"
                >
                  <v-icon left dark>mdi-alarm-light-outline</v-icon> Identify
                </v-btn>
                <v-btn
                  depressed
                  small
                  dark
                  color="orange"
                  @click="upgrade(truck.id)"
                  class="hidden-sm-and-down"
                >
                  <v-icon left dark>mdi-update</v-icon> Update OS
                </v-btn>
                <v-btn
                  depressed
                  small
                  dark
                  color="red"
                  @click="reboot(truck.id)"
                  class="hidden-sm-and-down"
                >
                  <v-icon left dark>mdi-restart</v-icon> Reboot
                </v-btn>

                <v-btn
                  depressed
                  small
                  dark
                  color="pink"
                  @click="setOvernightAddress"
                  :disabled="!truck.latitude || !truck.longitude"
                  class="hidden-sm-and-down"
                >
                  <v-icon left dark>mdi-map</v-icon> Set Overnight Address
                </v-btn>
              </v-btn-toggle>
            </v-menu>

            <!-- <h1 class="headline d-inline d-sm-block">
              <v-icon>mdi-truck</v-icon> Truck {{ truck.truck_id }}
            </h1> -->
            <h4
              class="mt-0 pt-0 d-flex align-center"
              @click="showOvernightDialog = true"
            >
              <span>
                <span v-if="getCardinalDirection">
                  <RealtimeDataItem :value="getCardinalDirection" /> -
                </span>
                <span v-if="truck.address">
                  <RealtimeDataItem :value="truck.address" />,
                </span>
                <span v-if="truck.city">
                  <RealtimeDataItem :value="truck.city" />,
                </span>
                <span v-if="truck.region">
                  <RealtimeDataItem :value="truck.region" />
                </span>
              </span>
              <country-flag
                v-if="truck.country"
                :country="truck.country | countryCode"
                size="normal"
              />
            </h4>
          </v-col>

          <v-col cols="12" sm="3" class="d-flex">
            <v-chip
              @click="$emit('chipClick', 'campaign', truck.campaign)"
              v-if="truck.campaign"
              small
              dark
              class="mr-2 indigo"
              >{{ truck.campaign }}</v-chip
            >
            <v-chip
              @click="$emit('chipClick', 'environment', truck.environment)"
              class="mr-2"
              small
              dark
              :class="environmentChipStyle"
              >{{ truckEnvironment }}</v-chip
            >
            <v-chip class="red mr-2" v-if="truck.apple" small dark
              ><v-icon>mdi-apple</v-icon></v-chip
            >
            <v-chip
              @click="
                $emit('chipClick', 'bluetooth_works', truck.bluetooth_works)
              "
              class="red mr-2"
              v-if="!truck.bluetooth_works"
              small
              dark
              >Bluetooth failure</v-chip
            >
            <v-chip
              @click="$emit('chipClick', 'is_moving', truck.is_moving)"
              class="teal mr-2"
              v-if="truck.is_moving"
              small
              dark
              >Moving</v-chip
            >
            <v-chip
              @click="$emit('chipClick', 'gps_works', truck.gps_works)"
              class="red mr-2"
              v-if="!truck.gps_works"
              small
              dark
              >GPS failure</v-chip
            >
            <v-chip
              @click="$emit('chipClick', 'wifi_works', truck.wifi_works)"
              class="red mr-2"
              v-if="!truck.wifi_works"
              small
              dark
              >WIFI failure</v-chip
            >
            <v-chip
              @click="$emit('chipClick', 'in_office', truck.in_office)"
              class="pink mr-2"
              v-if="truck.in_office"
              small
              dark
              >In Office</v-chip
            >
          </v-col>

          <v-col cols="12" sm="4">
            <div class="text-right">
              <v-icon>mdi-update</v-icon> Version
              <strong><RealtimeDataItem :value="truck.version" /> </strong> on
              base image
              <strong><RealtimeDataItem :value="truck.base_version" /></strong>
              <br />
              <small>
                <v-icon>mdi-heart-pulse</v-icon>
                Last updated
                <RealtimeDataItem
                  :value="truck.modified_at | millisecondDate"
                />
              </small>
            </div>
          </v-col>
        </v-row>

        <ExploreFleetCardDetails :truck="truck" @copySerial="copySerial" />
      </v-col>
    </v-row>

    <v-divider class="my-8"></v-divider>
    <v-dialog
      v-model="showOvernightDialog"
      max-width="700"
      :persistent="processingOvernight"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Truck {{ truck.truck_id }}
        </v-card-title>

        <v-card-text class="d-flex flex-column align-center mt-4 py-4">
          <h4><v-icon>mdi-map-marker-outline</v-icon> Location</h4>
          <h2 class="my-4">
            {{ truck.address }} {{ truck.city }}, {{ truck.region }}
          </h2>
          <h6>
            Latitude: {{ truck.latitude }}, Longitude: {{ truck.longitude }}
          </h6>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="setOvernightAddress"
            :disabled="!truck.latitude || !truck.longitude"
          >
            Set Overnight Address
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :right="true"
      :top="true"
      :min-width="300"
      :color="`${snackbar.color} darken-4`"
    >
      <v-icon large>{{ snackbar.icon }}</v-icon>
      <span class="font-weight-medium ml-3">{{ snackbar.message }}</span>
    </v-snackbar>
  </v-card>
</template>
<script>
import moment from "moment";

export default {
  name: "ExploreFleetCard",
  components: {
    RealtimeDataItem: () => import("@/components/RealtimeDataItem"),
    ExploreFleetCardDetails: () =>
      import("@/components/ExploreFleetCardDetails"),
    CountryFlag: () => import("vue-country-flag"),
  },
  props: {
    truck: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showOvernightDialog: false,
    processingOvernight: false,
    snackbar: { show: false, color: "red", icon: "", message: "" },
  }),
  filters: {
    millisecondDate: function (date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
  },
  computed: {
    getCardinalDirection() {
      let angle = this.truck.mag_track;
      if (typeof angle === "string") angle = parseInt(angle);
      if (angle <= 0 || angle > 360 || typeof angle === "undefined")
        return false;
      const arrows = {
        north: "↑ N",
        north_east: "↗ NE",
        east: "→ E",
        south_east: "↘ SE",
        south: "↓ S",
        south_west: "↙ SW",
        west: "← W",
        north_west: "↖ NW",
      };
      const directions = Object.keys(arrows);
      const degree = 360 / directions.length;
      angle = angle + degree / 2;
      for (let i = 0; i < directions.length; i++) {
        if (angle >= i * degree && angle < (i + 1) * degree)
          return arrows[directions[i]];
      }
      return arrows["north"];
    },
    environmentChipStyle() {
      const environments = [
        "lime",
        "light-green",
        "blue",
        "amber",
        "cyan",
        "deep-orange",
      ];
      return environments[this.truck.environment];
    },
    // (0, 'STANDBY'), (1, 'PREPRODUCTION'), (2, 'PRODUCTION'), (3, 'DEVELOPMENT'), (4, 'TRANSIT'), (5, 'DECOMISSIONED'), (6, 'UNLISTED')
    truckEnvironment() {
      const environments = [
        "Standby",
        "Preproduction",
        "Production",
        "Development",
        "In-transit",
        "Decomissioned",
        "Unlisted",
      ];
      return environments[this.truck.environment];
    },
  },
  methods: {
    async setOvernightAddress() {
      try {
        const { id, truck_id, environment, latitude, longitude } = this.truck;
        this.processingOvernight = true;
        await this.$api.updateDeviceOvernightAddress(
          truck_id,
          id,
          environment,
          { latitude, longitude }
        );
      } catch (e) {
        this.snackbar = {
          show: true,
          color: "red",
          icon: "mdi-close-octagon-outline",
          message: "Failed to set overnight address",
        };
      }

      this.processingOvernight = false;
      this.showOvernightDialog = false;
    },
    async upgrade(device) {
      try {
        await this.$api.sendUpgradeCommand(device);
      } catch (e) {
        console.log(e);
      }

      this.snackbar = {
        show: true,
        color: "success",
        icon: "mdi-check",
        message: `Sent upgrade command to ${device}`,
      };
    },
    async reboot(device) {
      try {
        await this.$api.sendRebootCommand(device);
      } catch (e) {
        console.log(e);
      }
      this.snackbar = {
        show: true,
        color: "success",
        icon: "mdi-check",
        message: `Sent reboot command to ${device}`,
      };
    },
    async identify(device) {
      try {
        await this.$api.sendIdentifyCommand(device);
      } catch (e) {
        console.log(e);
      }
      this.snackbar = {
        show: true,
        color: "success",
        icon: "mdi-check",
        message: `Sent identify command to ${device}`,
      };
    },
    copySerial() {
      navigator.clipboard.writeText(this.truck.id);
      this.snackbar = {
        show: true,
        color: "success",
        icon: "mdi-check",
        message: `Device Serial for Truck ${this.truck.truck_id} copied to Clipboard`,
      };
    },
  },
};
</script>
<style scoped>
.failure {
  background: #ffebee;
  border-left: 4px solid #f44336 !important;
}
.Decomissioned {
  border-left: 4px solid #607d8b;
}
.Preproduction {
  border-left: 4px solid #7b126d;
}
.Production {
  border-left: 4px solid #8bc34a;
}
.Development {
  border-left: 4px solid #03a9f4;
}
.Standby {
  border-left: 4px solid #ffeb3b;
}
.In-transit {
  border-left: 4px solid #00bcd4;
}
.Unprovisioned {
  border-left: 4px solid #ff5722;
}
</style>
